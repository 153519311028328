import { useTranslation } from 'next-i18next';
import EmailForm from '../homepage/forms/EmailForm';

const PhoneOffer = () => {
  const { t } = useTranslation(['common']);

  return (
    <section className="flex w-full items-center justify-center rounded-xl border border-gray-6 tablet:px-8">
      <div className="flex w-full flex-col tablet:max-w-[780px] tablet:flex-row tablet:justify-between tablet:gap-8">
        <aside className="flex shrink-0 justify-center rounded-t-xl bg-gray-7 px-11 pt-6 tablet:h-65 tablet:bg-transparent tablet:px-0">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            alt="App Screenshot"
            className="max-h-67 tablet:max-h-59"
            src="/promotional/PhoneOffer.png"
          />
        </aside>

        <main className="flex shrink flex-col px-4 pt-4 pb-6 mobile:px-6 mobile:pt-6 mobile:pb-8 tablet:max-w-[485px] tablet:justify-center tablet:px-0">
          <h3 className="mb-2 font-medium text-black text-header-3 tracking-tight">
            {t('phoneOffer.title')}
          </h3>

          <p className="mb-6 text-gray-1">{t('phoneOffer.text')}</p>

          <EmailForm className="!max-w-[unset]" />
        </main>
      </div>
    </section>
  );
};

export default PhoneOffer;
