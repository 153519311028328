import PhoneOffer from '@/components/global/PhoneOffer';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Button } from 'ui';

/**
 * This is to handle translation server-side.
 */
export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale || 'en-US', [
        'errors',
        'common',
        'homepage',
        'blog',
        'footer',
      ])),
    },
  };
}

export default ({ button = true }: { button?: boolean }) => {
  const { t } = useTranslation(['errors']);
  const router = useRouter();

  useEffect(() => {
    router.push('/');
  });

  return (
    <main className="flex h-[calc(100vh-160px)] w-full flex-col items-center justify-center gap-20">
      <div className="flex w-full max-w-90 flex-col items-center px-10 text-center">
        <h1 className="mb-5 pt-20 font-medium text-header-2 mobile:text-header-d-2">
          {t('404.title')}
        </h1>
        <p className="mb-6 text-gray-4">{t('404.subtitle')}</p>
        {button && (
          <Link href="/">
            <a className="w-full max-w-64">
              <Button
                className="w-full border border-gray-6 hover:text-black focus:text-black active:text-black"
                variant="link"
              >
                {t('404.button')}
              </Button>
            </a>
          </Link>
        )}
      </div>
      <div className="w-full max-w-[780px]">
        <PhoneOffer />
      </div>
    </main>
  );
};
